import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://701879b1b3362ac26db59b564d670d36@o4505692425158656.ingest.us.sentry.io/4507963680882688",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.5,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});